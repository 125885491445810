import axios from "axios";
import { API_URL } from "@/config/http-constants";
import { useAuthStore } from "@/store/auth";

export default {
  async reconcileTicket(ticketId, loggedInUserId, inventoryStatus) {
    const url = `${API_URL}/api/reconcileticket`;
    const authStore = useAuthStore();
    const token = authStore.user.Token;

    try {
      const response = await axios.post(
        url,
        {
          ticketId: ticketId,
          loggedInUserId: loggedInUserId,
          inventoryStatus: inventoryStatus,
        },
        {
          headers: {
            "X-Access-Token": `${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error reconciling ticket:", error.response || error);
      throw error;
    }
  },
};
