<template>
  <div class="unscreened-icon">
    <div class="icon-border">
      <span class="icon-text">U</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnscreenedIcon",
};
</script>

<style scoped>
.unscreened-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.icon-border {
  background-color: white;
  border: 2.5px solid currentColor;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.icon-text {
  color: black;
  font-size: 20px;
  font-weight: 800;
  margin: 0 1px;
}
</style>
