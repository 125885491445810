import axios from "axios";
import { API_URL } from "@/config/http-constants";

export default {
  async getEmbryologists() {
    const url = `${API_URL}/api/embryologistlist`;

    try {
      const response = await axios.get(url);

      console.log("Successfully retrieved embryologist list:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching embryologists:", error.response || error);
      throw error;
    }
  },
};
