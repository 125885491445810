import axios from "axios";
import { API_URL } from "@/config/http-constants";
import { useAuthStore } from "@/store/auth";

export default {
  async renewToken() {
    const url = `${API_URL}/api/renewSession/renewToken`;
    const authStore = useAuthStore();

    const currentToken = authStore.user.Token;

    try {
      const response = await axios.post(url, {
        token: currentToken,
        backgroundRenewCycle: true,
      });

      if (response.data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Token renewal error:", error.response || error);
      return false;
    }
  },
};
