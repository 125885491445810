<template>
  <div class="order-cancel-container">
    <div class="message">
      <span class="icon">⭕</span>
      <p>The order has been cancelled</p>
      <p>Open the drawer to remove the CryoTransporter</p>
    </div>
    <div class="button-row">
      <button class="open-drawer-btn" @click="openDrawer">Open Drawer</button>
      <button class="return-btn" @click="returnToFlightboard">
        Return to Flight Board
      </button>
      <button class="sign-out-btn" @click="signOut" :disabled="loading">
        Sign Out
      </button>
    </div>
    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img class="spinner" src="@/assets/images/spinner.gif" alt="Loading..." />
    </div>
  </div>
</template>

<script>
import LogoutService from "@/services/LogoutService";

export default {
  name: "OrderCancel",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    returnToFlightboard() {
      this.$router.push({ name: "FlightBoard" });
    },
    openDrawer() {
      this.$router.push({ name: "LoadTransporter" });
    },
    async signOut() {
      try {
        this.loading = true;
        await LogoutService.logout();
        this.$router.push({ name: "Login" });
      } catch (error) {
        console.error("Error signing out:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.order-cancel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #00122e;
  color: #ffffff;
}
.message {
  margin-bottom: 40px;
}
.icon {
  font-size: 48px;
  color: #e70026;
  margin-bottom: 20px;
  display: inline-block;
}
.message p {
  font-size: 24px;
  margin: 0;
}
.button-row {
  display: flex;
  gap: 20px;
}
button {
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
}
.open-drawer-btn {
  background-color: #164170;
  color: #ffffff;
  border: none;
}
.return-btn {
  background-color: #d0d2d3;
  color: #164170;
  border: none;
}
.sign-out-btn {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.spinner {
  width: 200px;
  height: 200px;
}
</style>
