// RfidMapper.js
// A component that maps the received json into detectedBeacon variable object and returns that
// The detectedBeacon object contains only the objects that are in the rightMap and whose tagDetected is true
// The detectedBeacon object also filters out the objects whose row and column are not from 0 to 4 on the rightMap
// The detectedBeacon object also excludes the object at row 2 and column 2

export default {
  data() {
    return {
      detectedBeacon: null,
    };
  },

  methods: {
    mapRfidData(json) {
      if (!json || !json.rightMap) {
        console.error("Invalid RFID data:", json);
        return [];
      }
      this.detectedBeacon = [];
      const rightMap = json.rightMap;
      for (let i = 0; i < rightMap.length; i++) {
        if (i > 4) break;
        for (let j = 0; j < rightMap[i].length; j++) {
          if (j > 4) break;
          if (i === 2 && j === 2) continue;
          const item = rightMap[i][j];
          if (item.tagDetected) {
            this.detectedBeacon.push(item);
          }
        }
      }
      return this.detectedBeacon;
    },
  },
};
