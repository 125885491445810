<template>
  <div
    class="h-screen flex flex-col bg-gradient-to-br from-tmrw-gradient-blue-start to-tmrw-gradient-blue-end overflow-auto relative"
  >
    <CryoTransporter class="w-full h-1/3" :detected-beacon="detectedBeacon" />

    <rfid-mapper ref="rfidMapper" :rfid-data="rfidData"></rfid-mapper>

    <div class="h-screen flex flex-col">
      <div class="w-full flex-1 flex flex-col items-center justify-center">
        <div class="flex justify-between items-center w-11/12">
          <div class="flex">
            <button
              @click="selectedType = 0"
              :class="[
                'px-4 py-2 rounded-0',
                { 'bg-white text-tmrw-blue': selectedType === 0 },
                { 'bg-tmrw-blue-dark text-white': selectedType !== 0 },
              ]"
            >
              Store
            </button>
            <button
              @click="selectedType = 1"
              :class="[
                'px-4 py-2 rounded-0',
                { 'bg-white text-tmrw-blue': selectedType === 1 },
                { 'bg-tmrw-blue-dark text-white': selectedType !== 1 },
              ]"
            >
              Retrieve
            </button>
          </div>
          <div class="flex items-center">
            <input
              type="checkbox"
              id="show-hidden-tickets"
              v-model="showHiddenTickets"
              class="form-checkbox h-5 w-5 text-white border-white rounded-full"
            />
            <label for="show-hidden-tickets" class="text-white ml-2">
              Show Hidden Tickets
            </label>
            <RefreshButton class="ml-4" @click="refreshTickets" />
          </div>
        </div>

        <div class="bg-white shadow overflow-y-auto w-11/12 flex-1">
          <table class="flightboard-table w-full text-left">
            <thead>
              <tr class="bg-white text-tmrw-blue">
                <th class="px-4 py-2 text-left"></th>
                <th class="px-4 py-2 text-left">Date and Time</th>
                <th class="px-4 py-2 text-left">Ticket #</th>
                <th class="px-4 py-2 text-left">Patient Name</th>
                <th class="px-4 py-2 text-left">Patient ID</th>
                <th class="px-4 py-2 text-left">Infectious Screening Status</th>
                <th class="px-4 py-2 text-left">Procedure</th>
                <th class="px-4 py-2 text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ticket in filteredTickets"
                :key="ticket.Id"
                class="border-b border-tmrw-gray"
              >
                <td class="px-4 py-2 text-left">
                  <input
                    type="checkbox"
                    v-model="ticket.selected"
                    :disabled="!isTicketSelectable(ticket, tickets)"
                    class="form-checkbox h-5 w-5 text-tmrw-blue-light"
                  />
                </td>
                <td class="px-4 py-2 text-left">
                  {{ formatDateTime(ticket.ProcedureTime) }}
                </td>
                <td class="px-4 py-2 text-left">{{ ticket.TicketId }}</td>
                <td class="px-4 py-2 text-left">
                  {{ getPatientName(ticket) }}
                </td>
                <td class="px-4 py-2 text-left">
                  {{ ticket.Patient.IdentificationNumber }}
                </td>
                <td class="px-4 py-2 text-left">
                  <div class="flex items-center">
                    <component
                      :is="
                        getScreeningStatusIcon(
                          getFirstChildScreeningStatus(ticket)
                        ).icon
                      "
                    />
                    <span class="ml-2">{{
                      getScreeningStatusIcon(
                        getFirstChildScreeningStatus(ticket)
                      ).text
                    }}</span>
                  </div>
                </td>
                <td class="px-4 py-2 text-left">{{ ticket.ProcedureName }}</td>
                <td class="px-4 py-2 text-left">
                  <EditIcon
                    @click="startReconciliation(ticket)"
                    class="ml-2 cursor-pointer"
                    :color="ticket.InventoryUpdate ? 'st1' : 'tmrw-green'"
                    v-if="
                      ticket.ProcedureName !== 'Import Cassette' &&
                      ticket.ProcedureName !== 'Batch'
                    "
                  />
                </td>
              </tr>
              <tr v-if="!filteredTickets.length">
                <td colspan="8" class="px-4 py-2 text-tmrw-blue text-left">
                  No tickets available
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex justify-end w-full px-4 py-2">
        <MainButton
          @click="cancelOrder"
          color="white-blue"
          outline
          class="mr-4"
        >
          Cancel
        </MainButton>

        <MainButton
          @click="updateTicketStatus"
          :color="selectedTickets.length ? 'primary' : 'dimmed'"
        >
          Start
        </MainButton>
      </div>
    </div>
    <InvalidAction
      :show="showInvalidActionPopup"
      :message="invalidActionMessage"
      @close="closeInvalidActionPopup"
    />
    <!-- Loading spinner -->
    <div
      v-if="loading"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img class="spinner" src="@/assets/images/spinner.gif" alt="Loading..." />
    </div>
  </div>
</template>
<script>
import CryoTransporter from "@/components/CryoTransporter/CryoTransporter.vue";
import FlightBoardService from "@/services/FlightBoardService";
import UpdateTransferStatusService from "@/services/UpdateTransferStatusService";
import RfidMapper from "@/components/RfidMapper";
import { useReconcileTicketStore } from "@/store/reconcileTicket.js";
import { useRfidDataStore } from "@/store/rfidData.js";
import EditIcon from "@/components/Icons/EditIcon.vue";
import MainButton from "@/components/Buttons/MainButton.vue";
import ScreeningUnknownIcon from "@/components/Icons/ScreeningUnknownIcon.vue";
import ScreenNegativeIcon from "@/components/Icons/ScreenNegativeIcon.vue";
import ScreenPositiveIcon from "@/components/Icons/ScreenPositiveIcon.vue";
import UnscreenedIcon from "@/components/Icons/UnscreenedIcon.vue";
import InvalidAction from "@/components/Popup/InvalidAction.vue";
import RefreshButton from "@/components/Icons/RefreshButton.vue";

export default {
  components: {
    CryoTransporter,
    RfidMapper,
    EditIcon,
    ScreeningUnknownIcon,
    ScreenNegativeIcon,
    ScreenPositiveIcon,
    UnscreenedIcon,
    MainButton,
    InvalidAction,
    RefreshButton,
  },
  data() {
    return {
      tickets: null,
      selectedTickets: [],
      rfidData: null,
      showHiddenTickets: false,
      detectedBeacon: null,
      selectedType: 0,
      showInvalidActionPopup: false,
      invalidActionMessage: "",
      loading: false,
    };
  },
  created() {
    this.fetchTickets();
    this.pollRfid();
  },
  methods: {
    async fetchTickets() {
      try {
        this.loading = true;
        this.tickets = await FlightBoardService.getTickets();
        console.log(this.tickets);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        this.loading = false;
      }
    },

    openDrawer() {
      this.$router.push({ name: "LoadTransporter" });
    },

    async cancelOrder() {
      this.$router.push({
        name: "OrderCancel",
      });
    },

    formatDateTime(dateTime) {
      return new Date(dateTime).toLocaleString("en-US", {
        timeZone: "UTC",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    closeInvalidActionPopup() {
      this.showInvalidActionPopup = false;
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },

    getScreeningStatusIcon(status) {
      switch (status) {
        case 1:
          return {
            icon: "ScreenPositiveIcon",
            text: "POSITIVE",
          };
        case 2:
          return {
            icon: "ScreenNegativeIcon",
            text: "NEGATIVE",
          };
        case 3:
          return {
            icon: "UnscreenedIcon",
            text: "UNSCREENED",
          };
        default:
          return {
            icon: "ScreeningUnknownIcon",
            text: "UNKNOWN",
          };
      }
    },

    //Get the screening status of first children for batch and bulk ticket types
    //For other ticket types, just return their own screening status
    getFirstChildScreeningStatus(ticket) {
      if (ticket.Children && ticket.Children.length > 0) {
        const firstChild = this.tickets.find(
          (t) => t.Id === ticket.Children[0]
        );
        return firstChild ? firstChild.ScreeningStatus : ticket.ScreeningStatus;
      }
      return ticket.ScreeningStatus;
    },

    isTicketSelectable(ticket, tickets) {
      // Check if the ticket has updateInventory as false
      const hasUpdateInventory = !ticket.InventoryUpdate;

      // For retrieve tickets, they are selectable if InventoryUpdate is false
      if (this.selectedType === 1) {
        return hasUpdateInventory;
      }

      // Get detected beacon ids
      const detectedBeaconIds = this.getDetectedBeaconIds();

      // Call getFilteredPresentTickets method to determine if the ticket is present
      const filteredPresentTickets = this.getFilteredPresentTickets(
        tickets,
        detectedBeaconIds
      );
      const isPresentTicket = filteredPresentTickets.some(
        (t) => t.Id === ticket.Id
      );

      // A ticket is selectable only if it has updateInventory as false and it is present
      return hasUpdateInventory && isPresentTicket;
    },

    getPatientName(ticket) {
      if (
        ticket.Patient.PatientName === "TMRW (DO NOT REMOVE IT) MASTER PATIENT"
      ) {
        if (ticket.ProcedureName === "Import Cassette") {
          return "Bulk Import";
        } else if (ticket.ProcedureName === "Batch") {
          return "Batch Import";
        } else {
          return ticket.Patient.PatientName;
        }
      } else {
        return ticket.Patient.PatientName;
      }
    },

    //Returns tickets if the beacon barcode is detected in rfid store
    //For parent ticket with children's, it checks if all of its children's beacon barcode is detected in rfid store
    //The beacon comparison in case insensitive as transfer api returns lower case beaconbarcode for children's ticket
    getFilteredPresentTickets(tickets, detectedBeaconIds) {
      const normalizedDetectedBeaconIds = detectedBeaconIds.map((beacon) =>
        beacon.toLowerCase()
      );
      const presentTickets = tickets.filter((ticket) => {
        if (ticket.Children && ticket.Children.length > 0) {
          // Check if all children's beacon IDs are present
          const allChildrenDetected = ticket.Children.every((childId) => {
            const childTicket = tickets.find((t) => t.Id === childId);
            return (
              childTicket &&
              normalizedDetectedBeaconIds.includes(childTicket.BeaconBarcode)
            );
          });
          return allChildrenDetected;
        } else {
          return normalizedDetectedBeaconIds.includes(
            ticket.BeaconBarcode.toLowerCase()
          );
        }
      });

      // Filter out child tickets from presentTickets
      return presentTickets.filter((ticket) => {
        return !tickets.some(
          (t) => t.Children && t.Children.includes(ticket.Id)
        );
      });
    },

    // Filter out child tickets from all tickets making sure only parent ticket is displayed
    getFilteredAllTickets(tickets) {
      return tickets.filter((ticket) => {
        return !tickets.some(
          (t) => t.Children && t.Children.includes(ticket.Id)
        );
      });
    },

    getDetectedBeaconIds() {
      return this.detectedBeacon
        ? this.detectedBeacon.map((beacon) => beacon.id)
        : [];
    },

    async startReconciliation(ticket) {
      if (ticket) {
        try {
          this.loading = true;
          const reconcileTicketStore = useReconcileTicketStore();
          reconcileTicketStore.setTicket(ticket);
          this.$router.push({
            name: "selectEmbryologist",
          });
        } catch (error) {
          console.error("Error reconciling ticket:", error);
        } finally {
          this.loading = false;
        }
      } else {
        console.error("No ticket provided for reconciliation");
      }
    },

    async updateTicketStatus() {
      const types = new Set(this.selectedTickets.map((ticket) => ticket.Type));

      if (types.size > 1) {
        this.invalidActionMessage =
          "Both store and retrieve tickets have been selected. Select only store or retrieve tickets.";
        this.showInvalidActionPopup = true;
        console.log("Invalid true: Both store and retrieve tickets selected");
      } else {
        if (this.selectedTickets && this.selectedTickets.length) {
          try {
            this.loading = true;

            const updateModels = [];

            this.selectedTickets.forEach((ticket) => {
              if (ticket.Children && ticket.Children.length > 0) {
                // If the ticket has children, extract their information
                ticket.Children.forEach((childId) => {
                  const childTicket = this.tickets.find(
                    (t) => t.Id === childId
                  );
                  if (childTicket) {
                    updateModels.push({
                      transferId: childTicket.Id,
                      ticketId: childTicket.TicketId,
                      transferType: childTicket.Type,
                      currentTicketState: childTicket.TicketState,
                    });
                  }
                });
              } else {
                // If the ticket doesn't have children, add its own information
                updateModels.push({
                  transferId: ticket.Id,
                  ticketId: ticket.TicketId,
                  transferType: ticket.Type,
                  currentTicketState: ticket.TicketState,
                });
              }
            });

            const response =
              await UpdateTransferStatusService.updateTransferStatus(
                updateModels
              );
            console.log("Tickets Frozen/Thawed Successfully", response);
            this.$router.push({
              name: "OrderSuccess",
            });
          } catch (error) {
            console.error("Error freezing/thawing tickets:", error);
          } finally {
            this.loading = false;
          }
        } else {
          console.log("No tickets selected");
        }
      }
    },
    refreshTickets() {
      this.fetchTickets();
    },
    async pollRfid() {
      try {
        this.rfidData = await useRfidDataStore().rfidData;
      } catch (error) {
        console.error("Error polling RFID data:", error);
      }
    },
  },

  watch: {
    tickets: {
      handler(tickets) {
        this.selectedTickets = tickets.filter((ticket) => ticket.selected);
      },
      deep: true,
    },
    rfidData: {
      handler(newVal) {
        this.detectedBeacon = this.$refs.rfidMapper.mapRfidData(newVal);
      },
      deep: true,
    },
  },

  computed: {
    displayedTickets() {
      let tickets = this.tickets || [];

      if (this.selectedType === 1) {
        // For the retrieve tab, return tickets of type retrieve without filtering by beaconId
        const filteredAllTickets = this.getFilteredAllTickets(tickets);
        return filteredAllTickets.filter((ticket) => ticket.Type === 1);
      } else {
        // For the store tab, filter tickets based on beaconId presence
        const detectedBeaconIds = this.getDetectedBeaconIds();
        const filteredPresentTickets = this.getFilteredPresentTickets(
          tickets,
          detectedBeaconIds
        );
        const filteredAllTickets = this.getFilteredAllTickets(tickets);
        return this.showHiddenTickets
          ? filteredAllTickets
          : filteredPresentTickets;
      }
    },

    filteredTickets() {
      // For the retrieve tab, only return tickets of type retrieve
      if (this.selectedType === 1) {
        return this.displayedTickets;
      } else {
        // For the store tab, filter by selectedType
        return this.displayedTickets.filter(
          (ticket) => ticket.Type === this.selectedType
        );
      }
    },

    rfidDataFromStore() {
      const rfidDataStore = useRfidDataStore();
      console.log("COMPUTED", rfidDataStore.rfidData);
      return rfidDataStore.rfidData;
    },
  },

  beforeUnmount() {
    clearInterval(this.rfidInterval);
  },
};
</script>

<style scoped>
.spinner {
  width: 200px;
  height: 200px;
}

.fixed.inset-0 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
</style>
