<template>
  <div class="screen-negative-icon">
    <div class="icon-border">
      <font-awesome-icon :icon="['fas', 'check']" class="icon" />
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCheck);

export default {
  name: "ScreenNegativeIcon",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.screen-negative-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.icon-border {
  background-color: white;
  border: 2.5px solid currentColor;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.icon {
  color: black;
  width: 18px;
  height: 18px;
  margin: 3px 4px 4px 2px;
}
</style>
