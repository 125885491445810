import axios from "axios";
import { MAPPING_READER_URL } from "@/config/http-constants";

export default {
  async getMappingReaderData() {
    const mappingReaderUrl =
      MAPPING_READER_URL ||
      "https://atr-athapa.cryogenics.life/biostore/MappingReader";
    console.log("MAPPER URL" + mappingReaderUrl);
    try {
      const response = await axios.get(mappingReaderUrl);
      return response.data;
    } catch (error) {
      console.error(
        "Error getting MappingReader data:",
        error.response || error
      );
      throw error;
    }
  },
};
