<template>
  <div class="relative">
    <!-- Logo -->
    <div class="absolute top-0 left-0 mt-4 ml-0">
      <TmrwLogo />
    </div>

    <!-- Sign In Form -->
    <div class="flex items-center justify-center h-screen bg-tmrw-blue-dark">
      <div class="max-w-md">
        <h2 class="text-3xl font-bold text-white mb-2">Sign In</h2>
        <p class="text-gray-300 mb-8" v-html="LOGIN_INSTRUCTIONS"></p>
        <form @submit.prevent="login">
          <!-- Login Input Fields -->
          <LoginInput
            id="username"
            v-model="username"
            type="text"
            placeholder="User Name"
            :disabled="loading"
          />
          <LoginInput
            id="password"
            v-model="password"
            type="password"
            placeholder="Password"
            class="mb-6"
            :disabled="loading"
          />
          <!-- Sign In and Cancel Buttons -->
          <div class="flex items-center justify-between">
            <MainButton type="submit" :disabled="loading">Sign in</MainButton>
            <MainButton
              type="button"
              color="secondary"
              @click="cancel"
              :disabled="loading"
              >Cancel</MainButton
            >
          </div>
          <!-- Error Message -->
          <p v-if="error" class="text-tmrw-error mt-4">{{ error }}</p>
        </form>
      </div>
    </div>

    <!-- Loading Spinner -->
    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img class="spinner" src="@/assets/images/spinner.gif" alt="Loading..." />
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import { useAuthStore } from "../../store/auth";
import MainButton from "../../components/Buttons/MainButton.vue";
import LoginInput from "../../components/InputField/LoginInput.vue";
import { LOGIN_INSTRUCTIONS } from "../../constants";
import TmrwLogo from "@/components/TmrwLogo/TmrwLogo.vue";

export default {
  components: {
    MainButton,
    LoginInput,
    TmrwLogo,
  },
  data() {
    return {
      username: "",
      password: "",
      error: "",
      LOGIN_INSTRUCTIONS,
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const { success, user, error } = await AuthService.authenticate(
          this.username,
          this.password
        );
        if (success) {
          const authStore = useAuthStore();
          authStore.setUser(user);
          this.$router.push("/open-drawer");
        } else {
          this.error = error.data.Message;
        }
      } catch (error) {
        console.error("Login error:", error);
        this.error = "Something went wrong!";
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      console.log("No feature available");
    },
  },
};
</script>

<style scoped>
.error-message {
  @apply text-tmrw-error mt-4;
}
.spinner {
  width: 200px;
  height: 200px;
}
</style>
