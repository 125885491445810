<template>
  <div class="date-time-picker">
    <div class="procedure-time">
      <span>Procedure Time</span>
      <div class="time-select flex justify-center">
        <select v-model="selectedHour" class="theme-input">
          <option v-for="hour in hours" :key="hour" :value="hour">
            {{ hour }}
          </option>
        </select>
        <span>:</span>
        <select v-model="selectedMinute" class="theme-input">
          <option v-for="minute in minutes" :key="minute" :value="minute">
            {{ minute }}
          </option>
        </select>
        <select v-model="selectedAmPm" class="theme-input">
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
    <div class="procedure-date">
      <span>Procedure Date</span>
      <div class="date-select">
        <button @click="previousMonth" class="previous-month">&lt;</button>
        <span>{{ currentMonth }}</span>
        <button @click="nextMonth" class="next-month">&gt;</button>
      </div>
      <div class="calendar">
        <!-- Render calendar days -->
        <div
          v-for="day in daysInMonth"
          :key="day"
          class="calendar-day"
          :class="{ selected: isSelectedDate(day) }"
          @click="selectDate(day)"
        >
          {{ day }}
        </div>
      </div>
    </div>
    <div class="button-area">
      <MainButton @click="cancel" color="white-blue" outline class="mr-4">
        Cancel
      </MainButton>
      <MainButton @click="next" color="primary"> Next </MainButton>
    </div>

    <!-- Loading spinner -->
    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img class="spinner" src="@/assets/images/spinner.gif" alt="Loading..." />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { useReconcileTicketStore } from "@/store/reconcileTicket.js";
import StoreWhoFrozeWhoThawed from "@/services/StoreWhoFrozeWhoThawed.js";
import MainButton from "@/components/Buttons/MainButton.vue";

export default {
  name: "DateTimePicker",
  components: {
    MainButton,
  },
  setup() {
    const reconcileTicketStore = useReconcileTicketStore();

    return {
      reconcileTicketStore,
      loading: false,
    };
  },
  data() {
    // Get the current time
    const currentTime = dayjs();

    return {
      selectedDate: currentTime,
      selectedHour: currentTime.format("hh"),
      selectedMinute: currentTime.format("mm"),
      selectedAmPm: currentTime.format("A"),
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: ["00", "15", "30", "45"],
    };
  },
  computed: {
    currentMonth() {
      return this.selectedDate.format("MMMM YYYY");
    },
    daysInMonth() {
      return Array.from(
        { length: this.selectedDate.daysInMonth() },
        (_, i) => i + 1
      );
    },
  },
  methods: {
    previousMonth() {
      this.selectedDate = this.selectedDate.subtract(1, "month");
    },
    nextMonth() {
      this.selectedDate = this.selectedDate.add(1, "month");
    },
    isSelectedDate(day) {
      return this.selectedDate.date() === day;
    },
    selectDate(day) {
      this.selectedDate = this.selectedDate.date(day);
    },
    async next() {
      this.loading = true;

      const selectedDateTime = dayjs(
        `${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedHour}:${
          this.selectedMinute
        } ${this.selectedAmPm}`,
        "YYYY-MM-DD hh:mm A"
      );
      this.reconcileTicketStore.setProcedureTimestamp(selectedDateTime);
      const formattedDateTime = selectedDateTime.format("YYYY-MM-DD HH:mm:ss");
      console.log("FORMATTED DATE TIME: ", formattedDateTime);

      try {
        await StoreWhoFrozeWhoThawed.storeWhoFrozeWhoThawed(
          this.reconcileTicketStore.ticket.TicketId,
          this.reconcileTicketStore.embryologistId,
          formattedDateTime
        );
        this.$router.push({ path: "/inventory-update/" });
        console.log("Successfully sent freeze/thaw details to the backend");
      } catch (error) {
        console.error("Error sending details:", error);
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      console.log("Ticket before clearing:", this.reconcileTicketStore.ticket);
      this.reconcileTicketStore.clearTicket();
      console.log("Ticket after clearing:", this.reconcileTicketStore.ticket);
      this.$router.push({ path: "/flightboard/" });
    },
  },
};
</script>

<style scoped>
.date-time-picker {
  @apply bg-white p-6 rounded-lg shadow-md;
}
.procedure-time {
  @apply mb-6;
}
.procedure-time span {
  @apply text-tmrw-blue text-lg font-semibold mb-2 block;
}
.time-select {
  @apply flex items-center;
}
.theme-input {
  @apply bg-tmrw-gray-light border border-tmrw-gray p-2 rounded-md mr-2;
}
.procedure-date {
  @apply mb-6;
}
.procedure-date span {
  @apply text-tmrw-blue text-lg font-semibold mb-2 block;
}
.date-select {
  @apply flex items-center mb-4;
}
.previous-month,
.next-month {
  @apply bg-tmrw-blue text-white px-2 py-1 rounded-md;
}
.previous-month {
  @apply mr-2;
}
.next-month {
  @apply ml-2;
}
.calendar {
  @apply grid grid-cols-7 gap-2;
}
.calendar-day {
  @apply bg-tmrw-gray-light text-center py-2 rounded-md cursor-pointer;
}
.calendar-day.selected {
  @apply bg-tmrw-blue text-white;
}
.button-area {
  @apply flex justify-end;
}
.cancel-button {
  @apply px-6 py-2 rounded bg-white text-tmrw-blue mr-4;
}
.next-button {
  @apply px-6 py-2 rounded bg-tmrw-green text-tmrw-blue;
}

.spinner {
  width: 200px;
  height: 200px;
}
</style>
