<template>
  <div
    class="flex flex-col items-center justify-between bg-tmrw-blue-dark min-h-screen overflow-auto relative"
  >
    <!-- Spinner -->
    <div
      v-if="showSpinner"
      class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img
        class="spinner"
        src="@/assets/images/spinner.gif"
        alt="Loading..."
        style="width: 200px; height: 200px"
      />
    </div>

    <div class="text-center mt-6 bb-2">
      <p class="text-white text-4xl mb-12">
        {{ CRYOTRANSPORTER_LOADED_TITLE }}
      </p>
      <div class="relative">
        <img
          src="@/assets/images/transporter-load.gif"
          alt="Transporter Remove"
          class="w-480 mx-auto"
        />
      </div>
    </div>
    <p class="text-white mb-8 mt-4" v-html="UNLOAD_INSTRUCTIONS"></p>
    <div class="text-white mb-12 checkmark-list">
      <div class="flex flex-col items-start">
        <label class="label flex items-center mb-4">
          <input
            type="checkbox"
            class="hidden"
            v-model="checklistItems.removedCryoTransporter"
          />
          <span class="checkbox mr-4"></span>
          <span>{{ CHECKLIST_LABEL_REMOVED }}</span>
        </label>
        <label class="label flex items-center">
          <input
            type="checkbox"
            class="hidden"
            v-model="checklistItems.safeToCloseDrawer"
          />
          <span class="checkbox mr-4"></span>
          <span>{{ CHECKLIST_LABEL_SAFE_TO_CLOSE }}</span>
        </label>
      </div>
    </div>
    <div class="mb-24">
      <MainButton
        color="primary"
        @click="closeDrawer"
        :disabled="!canCloseDrawer"
        >Close Drawer</MainButton
      >
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/Buttons/MainButton.vue";
import { useRfidDataStore } from "@/store/rfidData.js";
import {
  CRYOTRANSPORTER_LOADED_TITLE,
  UNLOAD_INSTRUCTIONS,
  CHECKLIST_LABEL_REMOVED,
  CHECKLIST_LABEL_SAFE_TO_CLOSE,
} from "@/constants.js";

export default {
  components: {
    MainButton,
  },
  data() {
    return {
      showSpinner: true,
      pollInterval: null,
      checklistItems: {
        removedCryoTransporter: false,
        safeToCloseDrawer: false,
      },
    };
  },
  computed: {
    canCloseDrawer() {
      return (
        this.checklistItems.removedCryoTransporter &&
        this.checklistItems.safeToCloseDrawer
      );
    },
    CRYOTRANSPORTER_LOADED_TITLE() {
      return CRYOTRANSPORTER_LOADED_TITLE;
    },
    UNLOAD_INSTRUCTIONS() {
      return UNLOAD_INSTRUCTIONS;
    },
    CHECKLIST_LABEL_REMOVED() {
      return CHECKLIST_LABEL_REMOVED;
    },
    CHECKLIST_LABEL_SAFE_TO_CLOSE() {
      return CHECKLIST_LABEL_SAFE_TO_CLOSE;
    },
  },
  mounted() {
    this.startPolling();
    setTimeout(() => {
      this.showSpinner = false;
    }, 4000);
  },
  beforeUnmount() {
    this.stopPolling();
  },
  methods: {
    //polling every 3 seconds
    startPolling() {
      const rfidDataStore = useRfidDataStore();
      this.pollInterval = setInterval(() => {
        rfidDataStore.pollRfid();
      }, 3000);
    },
    stopPolling() {
      clearInterval(this.pollInterval);
    },
    closeDrawer() {
      this.$router.push({ name: "FlightBoard" });
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid black;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.checkbox::after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label {
  @apply bg-white rounded-md px-4 py-2 text-black;
  width: 50vw;
  transition: background-color 0.3s, color 0.3s;
}

.label:has(input[type="checkbox"]:checked) {
  @apply bg-tmrw-green-checked text-white-checked;
}
input[type="checkbox"]:checked + .checkbox::after {
  display: block;
}

.spinner {
  width: 200px;
  height: 200px;
}

.absolute.inset-0 {
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

@media (min-width: 768px) {
  .min-h-screen {
    min-height: calc(100vh - 4rem);
  }
}
</style>
