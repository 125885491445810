import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    lastTokenRefresh: null,
  }),
  actions: {
    setUser(newUser) {
      this.user = newUser;
      this.lastTokenRefresh = Date.now();
    },
    removeUser() {
      this.user = null;
      this.lastTokenRefresh = null;
    },
    updateTokenRefreshTime() {
      this.lastTokenRefresh = Date.now();
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
