<template>
  <div class="screen-positive-icon">
    <div class="icon-border">
      <font-awesome-icon :icon="['fas', 'biohazard']" class="icon" />
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBiohazard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBiohazard);

export default {
  name: "ScreenPositiveIcon",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.screen-positive-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.icon-border {
  background-color: white;
  border: 2.5px solid currentColor;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.icon {
  color: black;
  width: 18px;
  height: 18px;
}
</style>
