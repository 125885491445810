<template>
  <button class="refresh-button" @click="$emit('click')">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="iconSize"
      :height="iconSize"
      viewBox="0 0 24 24"
      xml:space="preserve"
    >
      <path
        :class="iconColor"
        d="M17.65,6.35C16.2,4.9,14.21,4,12,4c-4.42,0-7.99,3.58-7.99,8s3.57,8,7.99,8c3.73,0,6.84-2.55,7.73-6h-2.08 c-0.82,2.33-3.04,4-5.65,4c-3.31,0-6-2.69-6-6s2.69-6,6-6c1.66,0,3.14,0.69,4.22,1.78L13,11h7V4L17.65,6.35z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    color: {
      type: String,
      default: "tmrw-blue",
    },
  },
  data() {
    return {
      isHovered: false,
      isPressed: false,
    };
  },
  computed: {
    iconColor() {
      return this.color === "tmrw-green" ? "st2" : "st1";
    },
    iconSize() {
      return this.isPressed ? 22 : 24;
    },
  },
};
</script>

<style scoped>
.refresh-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  width: v-bind(width + "px");
  height: v-bind(height + "px");
  min-width: v-bind(width + "px");
  min-height: v-bind(height + "px");
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s, border-color 0.3s;
}

.refresh-button:hover {
  background-color: #ffffff;
  border-color: #07162d;
}

.refresh-button:active {
  box-shadow: none;
}

.st1 {
  fill: #164170;
}

.st2 {
  fill: #48bc8c;
}
</style>
