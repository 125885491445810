<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 32 32"
    xml:space="preserve"
  >
    <g id="back-icon" transform="translate(33 33) rotate(180)">
      <g transform="matrix(-1, 0, 0, -1, 33, 33)">
        <circle id="Ellipse_1211-2" class="st0" cx="16" cy="16" r="16" />
      </g>
      <path
        id="Path_849"
        :class="iconColor"
        d="M11.52,14.03c0.06-0.06,0.09-0.14,0.09-0.22V9.38h13.75v13.75h-9.43 c-0.08,0-0.16,0.03-0.22,0.09l-1.25,1.25c-0.12,0.12-0.12,0.32,0.01,0.45c0.06,0.06,0.14,0.09,0.22,0.09h10.68 c1.04,0,1.88-0.84,1.88-1.88l0,0V9.38c0-1.04-0.84-1.88-1.88-1.88H11.61c-1.04,0-1.88,0.84-1.88,1.88v5.68 c0,0.17,0.14,0.31,0.31,0.31c0.08,0,0.16-0.03,0.22-0.09L11.52,14.03z M5.4,21.91c-0.89,0.89-0.89,2.34,0,3.23l1.69,1.69 c0.89,0.89,2.33,0.89,3.22,0.01c0,0,0,0,0.01-0.01l10.26-10.26l0.39-3.53c0.1-0.89-0.54-1.68-1.43-1.78 c-0.12-0.01-0.24-0.01-0.35,0l-3.53,0.39L5.4,21.91z M9.26,20.7l7.26-7.26l2.55-0.28l-0.28,2.55l-7.26,7.26L9.26,20.7z M6.73,23.81 c-0.16-0.16-0.16-0.42,0-0.58l1.2-1.2l2.27,2.27l-1.21,1.2c-0.16,0.16-0.42,0.16-0.58,0c0,0,0,0,0,0L6.73,23.81z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    color: {
      type: String,
      default: "st1",
    },
  },
  computed: {
    iconColor() {
      return this.color === "tmrw-green" ? "st2" : "st1";
    },
  },
};
</script>

<style scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #07162d;
}
.st2 {
  fill: #48bc8c;
}
</style>
