import { createRouter, createWebHistory } from "vue-router";
import LoginUser from "../views/Login/LoginUser";
import FlightBoard from "../views/FlightBoard/FlightBoard";
import SelectEmbryologist from "../views/ReconcileProcess/SelectEmbryologist/SelectEmbryologist";
import SelectProcedureDateTime from "../views/ReconcileProcess/SelectProcedureDateTime/SelectProcedureDateTime";
import InventoryUpdate from "../views/ReconcileProcess/InventoryUpdate/InventoryUpdate";
import OrderCancel from "../views/OrderStatus/OrderCancel";
import LoadTransporter from "../views/Transporter/LoadTransporter";
import OrderSuccess from "../views/OrderStatus/OrderSuccess";
import OpenDrawer from "../views/Transporter/OpenDrawer";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginUser,
  },
  {
    path: "/order-success",
    name: "OrderSuccess",
    component: OrderSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/open-drawer",
    name: "OpenDrawer",
    component: OpenDrawer,
    meta: { requiresAuth: true },
  },
  {
    path: "/order-cancel",
    name: "OrderCancel",
    component: OrderCancel,
    meta: { requiresAuth: true },
  },
  {
    path: "/load-transporter",
    name: "LoadTransporter",
    component: LoadTransporter,
    meta: { requiresAuth: true },
  },
  {
    path: "/flightboard",
    name: "FlightBoard",
    component: FlightBoard,
    meta: { requiresAuth: true },
  },
  {
    path: "/select-embryologist/",
    name: "selectEmbryologist",
    component: SelectEmbryologist,
    meta: { requiresAuth: true },
  },
  {
    path: "/select-procedure-date-time/",
    name: "selectProcedureDateTime",
    component: SelectProcedureDateTime,
    meta: { requiresAuth: true },
  },
  {
    path: "/inventory-update/",
    name: "inventoryUpdate",
    component: InventoryUpdate,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
