<template>
  <div
    id="app"
    class="h-screen flex flex-col bg-gradient-to-br from-tmrw-gradient-blue-start to-tmrw-gradient-blue-end overflow-auto font-exo text-xl"
    @mousemove="resetInactivityTimer"
    @keydown="resetInactivityTimer"
    style="text-align: center"
  >
    <router-view />
    <InactivityDialog
      ref="inactivityDialog"
      @activity="resetInactivityTimer"
      @logout="logout"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import RenewTokenService from "./services/RenewTokenService";
import InactivityDialog from "@/components/Popup/InactivityDialog.vue";

export default {
  name: "App",
  components: {
    InactivityDialog,
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const data = {
      interval: null,
      inactivityTimeout: null,
    };
    const inactivityDialog = ref(null);

    const renewToken = async () => {
      if (authStore.user) {
        const tokenRenewed = await RenewTokenService.renewToken();
        if (tokenRenewed) {
          authStore.updateTokenRefreshTime();
        } else {
          authStore.removeUser();
        }
      }
    };

    const setNextTokenRenewal = () => {
      const lastRefresh = authStore.lastTokenRefresh;
      const nextRefresh = lastRefresh + 7 * 60 * 1000;
      const now = Date.now();
      const delay = Math.max(nextRefresh - now, 0);
      data.interval = setTimeout(() => {
        renewToken().then(setNextTokenRenewal);
      }, delay);
    };

    const initializeTokenRenewal = () => {
      if (authStore.user && authStore.lastTokenRefresh) {
        setNextTokenRenewal();
      }
    };

    const startInactivityTimer = () => {
      if (authStore.user) {
        data.inactivityTimeout = setTimeout(() => {
          inactivityDialog.value.startInactivityTimer();
        }, 5 * 60 * 1000);
      }
    };

    const stopInactivityTimer = () => {
      clearTimeout(data.inactivityTimeout);
    };

    const resetInactivityTimer = () => {
      if (data.inactivityTimeout) {
        clearTimeout(data.inactivityTimeout);
        startInactivityTimer();
      }
    };

    const logout = () => {
      authStore.removeUser();
      router.push({ name: "Login" });
    };

    onMounted(() => {
      initializeTokenRenewal();
      startInactivityTimer();
    });

    watch(
      () => authStore.user,
      (newUser) => {
        if (newUser) {
          initializeTokenRenewal();
          startInactivityTimer();
        } else {
          stopInactivityTimer();
        }
      }
    );

    onUnmounted(() => {
      if (data.interval) {
        clearTimeout(data.interval);
        clearInterval(data.interval);
      }
      stopInactivityTimer();
    });

    return {
      data,
      inactivityDialog,
      resetInactivityTimer,
      logout,
    };
  },
};
</script>
