<!-- src/components/MainButton.vue -->
<template>
  <button
    :class="['btn', `btn-${color}`, { 'btn-outline': outline }]"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary",
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.btn {
  @apply font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline;
}

.btn-primary {
  @apply bg-tmrw-green text-tmrw-blue;
}

.btn-secondary {
  @apply bg-tmrw-gray-dark text-white;
}

.btn-dimmed {
  @apply bg-tmrw-green-dark text-tmrw-blue;
}

.btn-outline {
  @apply bg-transparent border;
}

.btn-white-blue {
  @apply bg-white text-tmrw-blue;
}
</style>
