<template>
  <div class="inventory-update">
    <h2 class="text-2xl font-bold mb-4">
      Please update CryoBeacon inventory to reflect changes
    </h2>
    <div v-if="isLoading" class="text-center">Loading...</div>
    <div v-else-if="error" class="text-red-500">
      Error fetching data: {{ error }}
    </div>
    <div v-else>
      <h3 class="text-lg font-semibold mb-2">Selected Cryodevices</h3>
      <div class="table-container">
        <table class="w-full mb-8">
          <thead>
            <tr class="bg-tmrw-blue-dark text-white">
              <th class="px-4 py-2">Cryodevice ID</th>
              <th class="px-4 py-2">Count x Type</th>
              <th class="px-4 py-2">Embryo #</th>
              <th class="px-4 py-2">Grade</th>
              <th class="px-4 py-2">Cryodate</th>
              <th class="px-4 py-2">Present</th>
              <th class="px-4 py-2">Not Present</th>
              <th class="px-4 py-2">Discarded</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="device in selectedCryoDevices"
              :key="device.SpecimenId"
              class="border-b"
            >
              <td class="px-4 py-2">{{ device.BeaconBarcode }}</td>
              <td class="px-4 py-2">
                {{ device.Count }} x {{ device.SpecimenType }}
              </td>
              <td class="px-4 py-2"></td>
              <td class="px-4 py-2"></td>
              <td class="px-4 py-2"></td>
              <td class="px-4 py-2">
                <input
                  type="radio"
                  :id="`present-${device.SpecimenId}`"
                  value="Present"
                  v-model="device.inventoryStatus"
                  class="form-radio text-tmrw-blue"
                />
              </td>
              <td class="px-4 py-2">
                <input
                  type="radio"
                  :id="`notpresent-${device.SpecimenId}`"
                  value="Not Present"
                  v-model="device.inventoryStatus"
                  class="form-radio text-tmrw-blue"
                />
              </td>
              <td class="px-4 py-2">
                <input
                  type="radio"
                  :id="`discarded-${device.SpecimenId}`"
                  value="Discarded"
                  v-model="device.inventoryStatus"
                  class="form-radio text-tmrw-blue"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3 class="text-lg font-semibold mb-2">Existing Cryodevices</h3>
      <div class="table-container">
        <table class="w-full">
          <thead>
            <tr class="bg-tmrw-blue-dark text-white">
              <th class="px-4 py-2">Cryodevice ID</th>
              <th class="px-4 py-2">Count x Type</th>
              <th class="px-4 py-2">Present</th>
              <th class="px-4 py-2">Not Present</th>
              <th class="px-4 py-2">Discarded</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="device in existingCryoDevices"
              :key="device.id"
              class="border-b"
            >
              <td class="px-4 py-2">{{ device.BeaconBarcode }}</td>
              <td class="px-4 py-2">
                {{ device.Count }} x {{ device.SpecimenType }}
              </td>
              <td class="px-4 py-2">
                <input
                  type="radio"
                  :id="`existing-present-${device.id}`"
                  value="Present"
                  v-model="device.inventoryStatus"
                  class="form-radio text-tmrw-blue"
                />
              </td>
              <td class="px-4 py-2">
                <input
                  type="radio"
                  :id="`existing-notpresent-${device.id}`"
                  value="Not Present"
                  v-model="device.inventoryStatus"
                  class="form-radio text-tmrw-blue"
                />
              </td>
              <td class="px-4 py-2">
                <input
                  type="radio"
                  :id="`existing-discarded-${device.id}`"
                  value="Discarded"
                  v-model="device.inventoryStatus"
                  class="form-radio text-tmrw-blue"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="button-area">
      <MainButton color="white-blue" @click="cancel">Cancel</MainButton>
      <MainButton
        :color="saveButtonColor"
        @click="updateStore"
        :disabled="isSaveDisabled"
        class="ml-4"
      >
        Save
      </MainButton>
    </div>

    <!-- Loading spinner -->
    <div
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img class="spinner" src="@/assets/images/spinner.gif" alt="Loading..." />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { useReconcileTicketStore } from "@/store/reconcileTicket.js";
import ReconcileTicketService from "@/services/ReconcileTicketService";
import TicketDetails from "@/services/TicketDetails";
import { useAuthStore } from "@/store/auth";
import MainButton from "@/components/Buttons/MainButton.vue";

export default {
  components: {
    MainButton,
  },
  computed: {
    store() {
      return useReconcileTicketStore();
    },
    isSaveDisabled() {
      return this.selectedCryoDevices
        .concat(this.existingCryoDevices)
        .some((device) => !device.inventoryStatus);
    },
    saveButtonColor() {
      return this.isSaveDisabled ? "dimmed" : "primary";
    },
  },

  data() {
    return {
      isLoading: false,
      error: null,
      selectedCryoDevices: [],
      existingCryoDevices: [],
    };
  },
  mounted() {
    this.fetchAndPopulateDevices();
  },
  methods: {
    async updateStore() {
      this.isLoading = true;
      // Get devices with updated inventoryStatus
      const updatedDevices = [
        ...this.selectedCryoDevices,
        ...this.existingCryoDevices,
      ];

      try {
        const authstore = useAuthStore();
        const loggedInUserId = authstore.user.Id;

        for (const device of updatedDevices) {
          const requestBody = {
            ticketId: this.store.ticket.TicketId,
            loggedInUserId: loggedInUserId,
            inventoryStatus: device.inventoryStatus,
          };

          const response = await ReconcileTicketService.reconcileTicket(
            requestBody.ticketId,
            requestBody.loggedInUserId,
            requestBody.inventoryStatus
          );
          console.log("API Response:", response);
        }

        this.store.clearTicket();
        this.$router.push({ path: "/flightboard" });
      } catch (error) {
        console.error("Error reconciling ticket:", error.response || error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchAndLogTicketDetails() {
      try {
        this.isLoading = true;
        const ticketDetails = await TicketDetails.getTicketDetails(
          this.store.ticket.TicketId
        );
        console.log("Fetched Ticket Details:", ticketDetails);
      } catch (error) {
        console.error("Error fetching ticket details:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAndPopulateDevices() {
      try {
        this.isLoading = true;
        this.error = null;

        const ticketDetails = await TicketDetails.getTicketDetails(
          this.store.ticket.TicketId
        );

        if (ticketDetails.ProcedureName.toLowerCase().includes("embryo")) {
          this.embryoNumberColumnHeaderText = "column will be shown";
        } else {
          this.embryoNumberColumnHeaderText = "";
        }

        this.selectedCryoDevices = _.orderBy(
          ticketDetails.Specimens.filter((x) => x.InSelectedTicket),
          ["SpecimenType"],
          ["desc"]
        );

        this.existingCryoDevices = _.orderBy(
          ticketDetails.Specimens.filter((x) => !x.InSelectedTicket),
          ["SpecimenType"],
          ["desc"]
        );

        this.selectedCryoDevices.forEach(
          (specimen) => (specimen.InventoryAction = "Required")
        );
        this.existingCryoDevices.forEach(
          (specimen) => (specimen.InventoryAction = "Required")
        );
      } catch (error) {
        this.error = error.message || "Unknown error";
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.store.clearTicket();
      this.$router.push({ path: "/flightboard" });
    },
  },
};
</script>

<style scoped>
.inventory-update {
  @apply bg-white p-6 rounded-lg shadow-md;
}

.table-container {
  @apply overflow-x-auto;
  max-height: calc(100vh - 350px);

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container table {
  min-width: 100%;
}

.specimen-type {
  @apply pr-8;
}

.button-area {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 50px);
}
</style>
