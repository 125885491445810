<template>
  <img src="@/assets/images/Logo.png" alt="TMRW-LOGO" class="tmrw-logo" />
</template>

<script>
export default {
  name: "TmrwLogo",
};
</script>

<style scoped>
.tmrw-logo {
  width: 200px;
  height: auto;
  margin-top: 5px;
}
</style>
