<template>
  <div
    v-if="show"
    class="fixed inset-0 flex items-center justify-center z-100000"
  >
    <div class="bg-white rounded-lg shadow-custom p-6 w-96 max-w-full">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-tmrw-blue text-xl font-semibold text-left">
          Invalid Action
        </h2>
        <button
          @click="closePopup"
          class="text-tmrw-blue hover:text-tmrw-blue-dark"
        >
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <p class="text-tmrw-blue mb-6 text-left">{{ message }}</p>
      <div class="flex justify-end">
        <button
          @click="closePopup"
          class="px-4 py-2 bg-tmrw-blue text-white rounded-lg hover:bg-tmrw-blue-dark"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
  },
};
</script>
