<template>
  <div class="flex items-center justify-center h-screen mt-4">
    <div class="grid grid-cols-5 gap-1">
      <div
        v-for="i in 25"
        :key="i"
        class="w-12 h-12 bg-tmrw-blue-dark p-1"
        :style="{ border: getBeaconId(i) ? '2px solid #4FD1C5' : 'none' }"
        :data-tooltip="getBeaconId(i)"
        @mouseover="handleMouseOver(getBeaconId(i))"
        @mouseleave="handleMouseLeave"
      >
        <div class="h-full overflow-hidden">
          <span v-if="getBeaconId(i)" class="text-white whitespace-nowrap">
            {{ getBeaconId(i) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detectedBeacon: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getBeaconId(index) {
      const row = Math.floor((index - 1) / 5);
      const column = (index - 1) % 5;

      if (this.detectedBeacon) {
        const beacon = this.detectedBeacon.find(
          (beacon) => beacon.row === row && beacon.column === column
        );
        return beacon ? beacon.id : null;
      }
      return null;
    },
    handleMouseOver(id) {
      if (id) {
        const element = event.target;
        element.setAttribute("title", id);
      }
    },
    handleMouseLeave() {
      const element = event.target;
      element.removeAttribute("title");
    },
  },
};
</script>

<style scoped>
.text-white {
  color: white;
}
.cursor-pointer:hover {
  cursor: pointer;
}
</style>
