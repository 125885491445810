<template>
  <div
    v-if="showDialog"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 w-96">
      <h2 class="text-2xl font-bold mb-4 text-tmrw-blue">
        {{ INACTIVITY_WARNING_TITLE }}
      </h2>
      <p class="text-tmrw-blue-dark mb-4">
        {{ INACTIVITY_WARNING_MESSAGE }}
        <span class="text-tmrw-blue-dark">{{ remainingTime }}</span> seconds.
      </p>
      <div class="flex justify-between">
        <button
          class="px-4 py-2 bg-tmrw-error text-white rounded-lg"
          @click="signMeOut"
        >
          {{ SIGN_OUT_BUTTON_TEXT }}
        </button>
        <button
          class="px-4 py-2 bg-tmrw-blue text-white rounded-lg"
          @click="keepMeSignedIn"
        >
          {{ KEEP_SIGNED_IN_BUTTON_TEXT }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  INACTIVITY_WARNING_TITLE,
  INACTIVITY_WARNING_MESSAGE,
  SIGN_OUT_BUTTON_TEXT,
  KEEP_SIGNED_IN_BUTTON_TEXT,
} from "@/constants";

export default {
  data() {
    return {
      showDialog: false,
      remainingTime: 120,
      countdownInterval: null,
      INACTIVITY_WARNING_TITLE,
      INACTIVITY_WARNING_MESSAGE,
      SIGN_OUT_BUTTON_TEXT,
      KEEP_SIGNED_IN_BUTTON_TEXT,
    };
  },
  methods: {
    startInactivityTimer() {
      this.showDialog = true;
      this.remainingTime = 120;
      this.countdownInterval = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime === 0) {
          clearInterval(this.countdownInterval);
          this.signMeOut();
        }
      }, 1000);
    },
    resetInactivityTimer() {
      this.showDialog = false;
      clearInterval(this.countdownInterval);
    },
    keepMeSignedIn() {
      this.resetInactivityTimer();
      this.$emit("activity");
    },
    signMeOut() {
      this.resetInactivityTimer();
      this.$emit("logout");
    },
  },
};
</script>

<style scoped>
button {
  min-width: 48%;
}
</style>
