<template>
  <div
    class="flex flex-col items-center justify-between h-screen bg-tmrw-blue-dark"
  >
    <div class="text-center mt-20">
      <p class="text-white text-4xl mb-12">{{ loadTransporterInstructions }}</p>
      <div class="relative">
        <img
          src="@/assets/images/transporter-remove.gif"
          alt="Transporter Load"
          class="w-600 mx-auto"
        />
      </div>
    </div>

    <div class="mb-24 mt-8">
      <MainButton color="primary" @click="openDrawer"> Open Drawer </MainButton>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/Buttons/MainButton.vue";
import { LOAD_TRANSPORTER_INSTRUCTIONS } from "@/constants.js";

export default {
  components: {
    MainButton,
  },
  data() {
    return {};
  },
  methods: {
    openDrawer() {
      this.$router.push({ name: "LoadTransporter" });
    },
  },
  computed: {
    loadTransporterInstructions() {
      return LOAD_TRANSPORTER_INSTRUCTIONS;
    },
  },
};
</script>
