<template>
  <div class="min-h-screen text-white p-6">
    <div v-if="ticket">
      <h1 class="text-2xl font-bold text-center mt-8 mb-8">
        Select the Embryologist who <br />
        performed the Freeze
      </h1>

      <div v-if="loading">
        <!-- Loading spinner -->
        <div
          class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <img
            class="spinner"
            src="@/assets/images/spinner.gif"
            alt="Loading..."
          />
        </div>
      </div>

      <div v-else-if="embryologists">
        <div class="table-container">
          <table class="w-full bg-white text-tmrw-blue-dark">
            <thead class="header">
              <tr>
                <th class="w-6"></th>
                <th class="px-2 py-2 font-normal">Login Name</th>
                <th class="px-2 py-2 font-normal">First Name</th>
                <th class="px-2 py-2 font-normal">Last Name</th>
                <th class="px-2 py-2 font-normal">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="embryologist in embryologists"
                :key="embryologist.Id"
                class="border-b border-tmrw-gray"
              >
                <td class="w-6 py-4">
                  <input
                    type="radio"
                    :value="embryologist.Id"
                    v-model="selectedEmbryologist"
                    class="form-radio text-tmrw-blue border-tmrw-blue"
                  />
                </td>
                <td class="px-2 py-4">{{ embryologist.LoginName }}</td>
                <td class="px-2 py-4">{{ embryologist.FirstName }}</td>
                <td class="px-2 py-4">{{ embryologist.LastName }}</td>
                <td class="px-2 py-4">{{ embryologist.EmailAddress }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-8 text-right button-area">
          <MainButton @click="cancel" color="white-blue" outline class="mr-4">
            Cancel
          </MainButton>
          <MainButton
            @click="next"
            :color="isEmbryologistSelected ? 'primary' : 'dimmed'"
            :disabled="!isEmbryologistSelected"
          >
            Next
          </MainButton>
        </div>
      </div>

      <div v-else-if="fetchError" class="text-tmrw-error">
        Error fetching embryologists: {{ fetchError }}
      </div>
      <div v-else>Loading embryologists...</div>
    </div>
    <div v-else class="mt-8 text-center">No ticket selected.</div>
  </div>
</template>

<script>
import { useReconcileTicketStore } from "@/store/reconcileTicket.js";
import EmbryologistList from "@/services/EmbryologistList";
import MainButton from "@/components/Buttons/MainButton.vue";

export default {
  components: {
    MainButton,
  },
  setup() {
    const reconcileTicketStore = useReconcileTicketStore();

    return {
      reconcileTicketStore,
    };
  },
  data() {
    return {
      embryologists: null,
      fetchError: null,
      selectedEmbryologist: null,
      loading: false,
    };
  },
  computed: {
    ticket() {
      return this.reconcileTicketStore.ticket;
    },
    procedureType() {
      return this.ticket && this.ticket.ProcedureName === "Embryo Freeze"
        ? "Freeze"
        : "Thaw";
    },
    isEmbryologistSelected() {
      return this.selectedEmbryologist;
    },
  },
  mounted() {
    this.fetchEmbryologists();
  },
  methods: {
    async fetchEmbryologists() {
      try {
        this.loading = true;
        this.embryologists = await EmbryologistList.getEmbryologists();
      } catch (error) {
        this.fetchError = error.message;
      } finally {
        this.loading = false;
      }
    },

    next() {
      this.reconcileTicketStore.setEmbryologistId(this.selectedEmbryologist);
      console.log(
        "Embryologist ID from store:",
        this.reconcileTicketStore.embryologistId
      );
      console.log("Selected Embryologist ID:", this.selectedEmbryologist);
      this.$router.push({ name: "selectProcedureDateTime" });
    },

    cancel() {
      this.reconcileTicketStore.clearTicket();
      this.$router.push({ name: "FlightBoard" });
    },
  },
};
</script>

<style>
.button-area {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 50px);
}
.table-container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header th {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
  color: var(--tmrw-blue-dark);
  border-bottom: 1px solid var(--tmrw-blue-dark);
}
.spinner {
  width: 200px;
  height: 200px;
}
</style>
