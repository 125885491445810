<template>
  <div class="order-success-container">
    <div class="message">
      <span class="icon">✅</span>
      <p class="text-2xl font-bold mb-4">Tickets completed</p>
      <p class="text-lg">1 ticket for Simulation User completed.</p>
      <div
        class="ticket-info bg-tmrw-yellow p-4 rounded-md mt-4 mx-auto"
        style="max-width: 24rem"
      >
        You have handled materials with an infectious screen status of Screened
        Positive. Follow your laboratory's instructions for cleaning procedures
        after handling infectious materials.
      </div>
    </div>
    <div class="button-row mt-8">
      <button
        class="open-drawer-btn bg-tmrw-green text-tmrw-blue-dark px-6 py-3 rounded-md mr-4"
        @click="openDrawer"
      >
        Open Drawer
      </button>
      <button
        class="return-btn bg-tmrw-gray text-tmrw-blue-dark px-6 py-3 rounded-md mr-4"
        @click="returnToFlightboard"
      >
        Return to Flight Board
      </button>
      <button
        class="sign-out-btn bg-tmrw-green text-tmrw-blue-dark border border-tmrw-blue-dark px-6 py-3 rounded-md"
        @click="signOut"
        :disabled="loading"
      >
        Sign Out
      </button>
    </div>
    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <img class="spinner" src="@/assets/images/spinner.gif" alt="Loading..." />
    </div>
  </div>
</template>

<script>
import LogoutService from "@/services/LogoutService";

export default {
  name: "OrderSuccess",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    returnToFlightboard() {
      this.$router.push({ name: "FlightBoard" });
    },
    openDrawer() {
      this.$router.push({ name: "LoadTransporter" });
    },
    async signOut() {
      try {
        this.loading = true;
        await LogoutService.logout();
        this.$router.push({ name: "Login" });
      } catch (error) {
        console.error("Error signing out:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.order-success-container {
  @apply flex flex-col items-center justify-center min-h-screen text-center bg-tmrw-blue text-white;
}
.message {
  @apply mb-8;
}
.icon {
  @apply text-4xl text-tmrw-green mb-4 inline-block;
}
.ticket-info {
  @apply mx-auto;
  max-width: 24rem;
}
.button-row {
  @apply flex;
}
.spinner {
  width: 200px;
  height: 200px;
}
</style>
