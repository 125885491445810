<template>
  <div class="screening-unknown-icon">
    <div class="icon-border">
      <font-awesome-icon :icon="['fas', 'question']" class="icon" />
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faQuestion);

export default {
  name: "ScreeningUnknownIcon",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.screening-unknown-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-border {
  background-color: white;
  border: 0 solid currentColor;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: currentColor;
  width: 16px;
  height: 16px;
  margin: 5px 6px 6px 5px;
}
</style>
