import axios from "axios";
import { API_URL } from "@/config/http-constants";

export default {
  async authenticate(username, password) {
    const url = `${API_URL}/api/login`;

    try {
      const response = await axios.post(url, {
        username,
        password,
      });

      const user = response.data.user;
      return { success: true, user };
    } catch (error) {
      console.error("Authentication failed:", error.response);
      return { success: false, error: error.response };
    }
  },
};
