<!-- src/components/LoginInput.vue -->
<template>
  <div class="mb-4">
    <input
      :class="inputClasses"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      required
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputClasses() {
      return [
        "appearance-none",
        "border",
        "rounded",
        "w-full",
        "py-2",
        "px-3",
        "text-gray-700",
        "leading-tight",
        "focus:outline-none",
        "focus:shadow-outline",
        "bg-white",
      ];
    },
  },
};
</script>
