const hostname = window.location.hostname;

let VUE_APP_CRYO_BACKEND_URL;
let VUE_APP_MAPPING_READER_URL;

if (hostname.includes("localhost")) {
  VUE_APP_CRYO_BACKEND_URL = "https://localhost:44302";
  VUE_APP_MAPPING_READER_URL =
    "https://atr-athapa.cryogenics.life/biostore/MappingReader";
} else {
  const subdomain = hostname.split("-")[2].split(".")[0];
  VUE_APP_CRYO_BACKEND_URL = `https://crs-cryo-${subdomain}.cryogenics.life`;
  VUE_APP_MAPPING_READER_URL = `https://atr-${subdomain}.cryogenics.life/biostore/MappingReader`;
}

const config = {
  VUE_APP_CRYO_BACKEND_URL,
  VUE_APP_MAPPING_READER_URL,
};

export default config;
