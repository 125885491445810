import { defineStore } from "pinia";

export const useReconcileTicketStore = defineStore("reconcileTicket", {
  state: () => ({
    ticket: null,
    embryologistId: null,
    procedureTimestamp: null,
    ticketInventoryUpdate: null,
  }),
  actions: {
    setTicket(newTicket) {
      this.ticket = newTicket;
    },
    recordInventoryUpdate(updateStatus) {
      this.ticketInventoryUpdate = updateStatus;
    },
    setEmbryologistId(id) {
      this.embryologistId = id;
    },
    setProcedureTimestamp(timestamp) {
      this.procedureTimestamp = timestamp;
    },
    clearTicket() {
      this.ticket = null;
      this.embryologistId = null;
      this.procedureTimestamp = null;
      this.ticketInventoryUpdate = null;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
