// src/constants.js

export const LOGIN_INSTRUCTIONS = `
  Your User and Password is required<br />
  the first time you sign in, each day.
`;

export const LOAD_TRANSPORTER_INSTRUCTIONS = "Ready to load CryoTransporter.";

export const PLEASE_LOAD_INSTRUCTIONS = "Please load CryoTransporter.";

export const CRYOTRANSPORTER_LOADED_TITLE = "CryoTransporter loaded";

export const UNLOAD_INSTRUCTIONS =
  "Unload the CryoTransporter, ensure all items have been<br /> checked, and select Close Drawer to continue.";

export const CHECKLIST_LABEL_REMOVED = "Have you removed the CryoTransporter?";

export const CHECKLIST_LABEL_SAFE_TO_CLOSE =
  "Is it safe to close the drawer without obstruction?";

//inactivity
export const INACTIVITY_WARNING_TITLE = "Inactivity Warning";
export const INACTIVITY_WARNING_MESSAGE =
  "Due to inactivity, you will be signed out in";
export const SIGN_OUT_BUTTON_TEXT = "Sign me out";
export const KEEP_SIGNED_IN_BUTTON_TEXT = "Keep me signed in";
