import axios from "axios";
import { API_URL } from "@/config/http-constants";
import { useAuthStore } from "@/store/auth";

export default {
  async getTickets() {
    const url = `${API_URL}/api/flightboard`;
    const authStore = useAuthStore();
    const token = authStore.user.Token;

    try {
      const response = await axios.get(url, {
        headers: {
          "X-Access-Token": `${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching tickets:", error.response || error);
      throw error;
    }
  },
};
