import axios from "axios";
import { API_URL } from "@/config/http-constants";

export default {
  async getTicketDetails(ticketId) {
    const url = `${API_URL}/api/ticketdetails?ticketId=${ticketId}`;

    try {
      const response = await axios.get(url);
      console.log("Successfully retrived ticket details:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching ticket details:", error.response || error);
      throw error;
    }
  },
};
