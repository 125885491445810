import { defineStore } from "pinia";
import BiostoreRfid from "@/services/BiostoreRfid";

export const useRfidDataStore = defineStore("rfidData", {
  state: () => ({
    rfidData: null,
  }),
  actions: {
    async pollRfid() {
      try {
        this.rfidData = await BiostoreRfid.getMappingReaderData();
        console.log(this.rfidData);
      } catch (error) {
        console.error("Error polling RFID data:", error);
      }
    },
    clearRfidData() {
      this.rfidData = null;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
